import React from "react"
import { Link } from "gatsby"
import { useContext } from "react"
import { LanguageContext } from "../locales/langContext"

const I18nSlug = ({ children, slug, path }) => {
  // expect a slug with slug, a path and a locale
  // Add trailing slash if this is not the home page
  const value = slug !== "" ? slug + `/` : ""
  const sanitizePath = path !== undefined ? path + `/` : ""
  const languageContext = useContext(LanguageContext)

  slug =
    languageContext.locale !== "it"
      ? `/` + languageContext.locale + `/` + sanitizePath + value
      : `/` + sanitizePath + value
  return (
    <Link
      to={slug}
      activeStyle={{ borderBottom: "2px solid #00793D", fontWeight: "bold" }}
      partiallyActive
    >
      {children}
    </Link>
  )
}

export default I18nSlug
