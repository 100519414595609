import React, { useContext, useState } from "react"
import { useCategory } from "../hooks/useCategory"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import I18nSlug from "./i18nSlug"
import { LanguageContext } from "../locales/langContext"

const MegaMenu = ({ anchor, links, path }) => {
  const [showMenu, setShowMenu] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const categoryLinks = useCategory()
  const langContext = useContext(LanguageContext)

  function getApplicationLinks(slug) {
    return categoryLinks.filter(obj => {
      return obj.application.slug === slug && obj.locale === langContext.locale
    })
  }

  return (
    <LanguageContext.Consumer>
      {t => (
        <Item
          onMouseEnter={() => setShowMenu(!showMenu)}
          onMouseLeave={() => setShowMenu(!showMenu)}
        >
          <a
            style={{
              borderBottom:
                isBrowser &&
                window.location.href.indexOf(anchor.toLowerCase()) > -1
                  ? "2px solid #00793D"
                  : "none",
            }}
          >
            {anchor}
          </a>
          {showMenu && (
            <Dropdown>
              {links.map(link => (
                <li>
                  <I18nSlug path={path} slug={link.slug}>
                    {link.title}
                  </I18nSlug>
                  <CategoryLinks>
                    {getApplicationLinks(link.slug, t.locale).map(
                      categoryLink => (
                        <li>
                          <I18nSlug
                            path={`${t.applications}/${link.slug}`}
                            slug={categoryLink.slug}
                          >
                            {categoryLink.title}
                          </I18nSlug>
                        </li>
                      )
                    )}
                  </CategoryLinks>
                </li>
              ))}
            </Dropdown>
          )}
        </Item>
      )}
    </LanguageContext.Consumer>
  )
}

const Item = styled.li`
  margin-bottom: 0;
  padding: 1rem 0;
  &:last-child {
    & > a {
      padding-right: 0;
    }
  }
  & > a {
    cursor: default;
    text-transform: capitalize;
    text-decoration: none;
  }
`

const Dropdown = styled.ul`
  margin-left: 0;
  margin-top: 1rem;
  text-align: left;
  background: ${DesignTokens.colors.white};
  position: absolute;
  right: 0;
  min-width: 80vw;
  display: flex;
  padding: 1rem;
  flex-direction: row;
  & > li {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 25%;
    & > a {
      text-transform: uppercase;
      font-weight: bold;
      text-align: left;
      width: 100%;
      padding: ${DesignTokens.spacing[2]} ${DesignTokens.spacing[2]};
      color: ${DesignTokens.colors.primary[500]};
      text-decoration: none;
      display: block;
      min-height: 60px;
      &:hover {
        color: white;
        background-color: ${DesignTokens.colors.primary[500]};
      }
    }
  }
`

const CategoryLinks = styled.ul`
  margin-top: 1rem;
  list-style: none;
  margin-left: 0;
  li {
    margin-bottom: 0;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    & > a {
      text-align: left;
      width: 100%;
      padding: ${DesignTokens.spacing[2]} 0;
      color: ${DesignTokens.colors.tertiary[300]};
      text-decoration: none;
      display: block;
      border-bottom: 1px solid #ccc;
      &:hover {
        color: ${DesignTokens.colors.primary[500]};
      }
    }
    &:last-child {
      & > a {
        border-bottom: none;
      }
    }
  }
`

export default MegaMenu
