import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import I18nSlug from "./i18nSlug"
import { getPagePath } from "./pathUtils"

const DropdownMenu = ({ anchor, links, path }) => {
  const [showMenu, setShowMenu] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const isActive = isBrowser && window.location.href.indexOf(path) > -1
  return (
    <Item
      onMouseEnter={() => setShowMenu(!showMenu)}
      onMouseLeave={() => setShowMenu(!showMenu)}
    >
      <Link
        style={{
          borderBottom: isActive ? "2px solid #00793D" : "none",
          fontWeight: isActive ? "bold" : "normal",
          cursor: "default",
        }}
      >
        {anchor}
      </Link>
      {showMenu && (
        <Dropdown>
          {links.map(link => (
            <li>
              {!link.model && (
                <I18nSlug path={path} slug={link.slug}>
                  {link.title || link.name}
                </I18nSlug>
              )}
              {link.model && link.model.apiKey === "page" && (
                <Link
                  to={`/${getPagePath(link)}`}
                  activeStyle={{
                    borderBottom: "2px solid #00793D",
                    fontWeight: "bold",
                  }}
                  partiallyActive
                >
                  {link.title || link.name}
                </Link>
              )}
            </li>
          ))}
        </Dropdown>
      )}
    </Item>
  )
}

const Item = styled.li`
  margin-bottom: 0;
  padding: 1rem 0;
  &:last-child {
    & > a {
      padding-right: 0;
    }
  }
  a {
    text-decoration: none;
  }
`

const Dropdown = styled.ul`
  margin-left: 0;
  margin-top: 1rem;
  text-align: left;
  background: ${DesignTokens.colors.white};
  position: absolute;
  display: flex;
  flex-direction: column;
  & > li {
    display: inline-flex;
    margin: 0;
    a {
      text-align: left;
      width: 100%;
      padding: ${DesignTokens.spacing[2]} ${DesignTokens.spacing[4]};
      color: ${DesignTokens.colors.primary[500]};
      text-decoration: none;
      &:hover {
        color: white;
        background-color: ${DesignTokens.colors.primary[500]};
      }
    }
  }
`

export default DropdownMenu
