import React, { useContext, useState } from "react"
import styled from "styled-components"
import { motion, useViewportScroll } from "framer-motion"
import logo from "../images/gavazzi-logo.png"
import { graphql, Link, useStaticQuery } from "gatsby"
import { LanguageContext } from "../locales/langContext"
import DropdownMenu from "./dropdownMenu"
import DropdownMobileNav from "./dropdownMobileNav"
import { useApplication } from "../hooks/useApplication"
import { useProduct } from "../hooks/useProduct"
import { usePage } from "../hooks/usePage"

import MegaMenu from "./megaMenu"
import { Box, Container, Flex, Image } from "@theme-ui/components"
import { Menu, Search, X } from "react-feather"
import {
  getContactPath,
  getHomePath,
  getSearchPath,
  getWherePath,
  getBlogPath,
  getNewsPath,
} from "./pathUtils"
import LanguageSwitcher from "./languageSwitcher"

const Nav = () => {
  const data = useStaticQuery(graphql`
    query NavQuery {
      allDatoCmsHelloBar {
        nodes {
          id
          text
          show
          locale
        }
      }
    }
  `)

  const [showHeader, setShowHeader] = useState(true)
  const [show, setShow] = useState(false)
  const { scrollY } = useViewportScroll()
  scrollY.onChange(() => {
    return scrollY.get() > 100 ? setShowHeader(false) : setShowHeader(true)
  })
  const languageContext = useContext(LanguageContext)
  const helloBar = data.allDatoCmsHelloBar.nodes.filter(obj => {
    return obj.locale === languageContext.locale
  })

  const applications = useApplication().filter(obj => {
    return obj.locale === languageContext.locale
  })

  const products = useProduct().filter(obj => {
    return obj.locale === languageContext.locale
  })
  const pages = usePage(languageContext.locale)

  return (
    <LanguageContext.Consumer>
      {t => (
        <Wrapper
          animate={{
            backgroundColor: showHeader
              ? "rgba(255, 255, 255, 1)"
              : "rgba(255, 255, 255, 0.85)",
          }}
          transition={{ duration: 0.5 }}
        >
          {helloBar[0].show && (
            <Box
              sx={{
                textAlign: "center",
                px: [1, 0],
                py: [3],
                fontSize: [2, 2],
                backgroundColor: "primary",
                color: "white",
                a: {
                  color: "white",
                  textDecoration: "underline",
                },
              }}
              dangerouslySetInnerHTML={{ __html: helloBar[0].text }}
            />
          )}

          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "1680px",
              paddingY: [2, 3],
            }}
          >
            <Box>
              <Link to={getHomePath(t.locale)}>
                <Image
                  sx={{
                    width: ["125px", "125px", "186px"],
                    minWidth: ["60px", "60px", "185px"],
                  }}
                  src={logo}
                  alt="Logo"
                />
              </Link>
            </Box>
            {/* MOBILE NAV */}
            <Box sx={{ display: ["block", "block", "none"] }}>
              <Menu role="button" tabIndex={0} onClick={() => setShow(!show)} />
            </Box>
            {/* DESKTOP NAV */}
            <Box sx={{ display: ["none", "none", "block"] }}>
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontSize: 1,
                }}
              >
                <Link
                  to={getBlogPath(t.locale)}
                  style={{
                    textDecoration: "none",
                    marginRight: "1rem",
                  }}
                  activeStyle={{
                    borderBottom: "2px solid #00793D",
                    fontWeight: "bold",
                  }}
                >
                  {t.blog}
                </Link>
                <Link
                  to={getWherePath(t.locale)}
                  style={{
                    textDecoration: "none",
                    marginRight: "1rem",
                  }}
                  activeStyle={{
                    borderBottom: "2px solid #00793D",
                    fontWeight: "bold",
                  }}
                >
                  {t.where}
                </Link>
                <Link
                  to={getContactPath(t.locale)}
                  style={{
                    textDecoration: "none",
                    marginRight: "1rem",
                  }}
                  activeStyle={{
                    borderBottom: "2px solid #00793D",
                    fontWeight: "bold",
                  }}
                >
                  {t.contacts}
                </Link>
                <Link to={getSearchPath(t.locale)}>
                  <Search />
                </Link>
                <LanguageSwitcher />
              </Flex>
              <MenuWrapper>
                {pages.map(rootPage => (
                  <DropdownMenu
                    key={rootPage.id}
                    anchor={rootPage.title}
                    links={rootPage.treeChildren}
                    path={rootPage.slug}
                  />
                ))}
                <DropdownMenu
                  anchor={
                    t.products.charAt(0).toUpperCase() + t.products.slice(1)
                  }
                  links={products}
                  path={t.products}
                />
                <MegaMenu
                  anchor={t.applications}
                  links={applications}
                  path={t.applications}
                />
                <Link
                  to={getNewsPath(t.locale)}
                  activeStyle={{
                    borderBottom: "2px solid #00793D",
                    fontWeight: "bold",
                  }}
                >
                  {t.insight}
                </Link>
              </MenuWrapper>
            </Box>
            {show && (
              <Box
                sx={{
                  position: "fixed",
                  backgroundColor: "primary",
                  width: "100%",
                  top: 0,
                  left: 0,
                  zIndex: 1000,
                  height: "100%",
                  overflowY: "auto",
                  color: "light",
                }}
              >
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <X
                    role="button"
                    tabIndex={0}
                    onClick={() => setShow(!show)}
                  />
                </Container>
                <Flex
                  as="ul"
                  sx={{
                    listStyle: "none",
                    margin: 0,
                    padding: 0,
                    flexDirection: "column",
                    alignItems: "center",
                    "& > li": {
                      paddingX: 3,
                      paddingY: 2,
                    },
                    a: { color: "light" },
                  }}
                >
                  {pages.map(rootPage => (
                    <DropdownMobileNav
                      key={rootPage.id}
                      anchor={rootPage.title}
                      links={rootPage.treeChildren}
                      path={rootPage.slug}
                    />
                  ))}
                  <DropdownMobileNav
                    anchor={
                      t.products.charAt(0).toUpperCase() + t.products.slice(1)
                    }
                    links={products}
                    path={t.products}
                  />
                  <DropdownMobileNav
                    anchor={
                      t.applications.charAt(0).toUpperCase() +
                      t.applications.slice(1)
                    }
                    links={applications}
                    path={t.applications}
                  />
                  <Box as="li">
                    <Link
                      to={getNewsPath(t.locale)}
                      style={{
                        textDecoration: "none",
                      }}
                      activeStyle={{
                        borderBottom: "2px solid #00793D",
                        fontWeight: "bold",
                      }}
                    >
                      {t.insight}
                    </Link>
                  </Box>
                </Flex>
                <Flex
                  as="ul"
                  sx={{
                    listStyle: "none",
                    margin: 0,
                    marginTop: 5,
                    padding: 0,
                    paddingTop: 3,
                    flexDirection: "column",
                    fontSize: 1,
                    borderTop: "1px solid",
                    borderColor: "#198a52",
                    alignItems: "center",
                    li: {
                      paddingX: 3,
                      paddingY: 2,
                    },
                    a: { color: "light" },
                  }}
                >
                  <Box as="li">
                    <Link
                      to={getBlogPath(t.locale)}
                      style={{
                        textDecoration: "none",
                      }}
                      activeStyle={{
                        borderBottom: "2px solid #00793D",
                        fontWeight: "bold",
                      }}
                    >
                      {t.blog}
                    </Link>
                  </Box>
                  <Box as="li">
                    <Link
                      to={getWherePath(t.locale)}
                      style={{
                        textDecoration: "none",
                      }}
                      activeStyle={{
                        borderBottom: "2px solid #00793D",
                        fontWeight: "bold",
                      }}
                    >
                      {t.where}
                    </Link>
                  </Box>
                  <Box as="li">
                    <Link
                      to={getContactPath(t.locale)}
                      style={{
                        textDecoration: "none",
                      }}
                      activeStyle={{
                        borderBottom: "2px solid #00793D",
                        fontWeight: "bold",
                      }}
                    >
                      {t.contacts}
                    </Link>
                  </Box>
                  <Box as="li">
                    <Link to={getSearchPath(t.locale)}>
                      <Search />
                    </Link>
                  </Box>
                  <LanguageSwitcher mobile />
                </Flex>
              </Box>
            )}
          </Container>
        </Wrapper>
      )}
    </LanguageContext.Consumer>
  )
}

const Wrapper = styled(motion.header)`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`

const MenuWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  list-style: none;
  height: 100%;
  align-items: center;
  & > * {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
  & > a {
    text-decoration: none;
  }
`

export default Nav
