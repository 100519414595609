import { useStaticQuery, graphql } from "gatsby"

export const usePage = locale => {
  const menu = useStaticQuery(graphql`
    query UsePageQuery {
      allDatoCmsPage(
        sort: { fields: [position], order: ASC }
        filter: {
          root: { eq: true }
          slug: { ne: null }
          inDepth: { eq: false }
        }
      ) {
        nodes {
          id
          locale
          title
          slug
          show
          root
          ...AllSlugLocales
          treeParent {
            slug
            root
            ...AllSlugLocales
          }
          treeChildren {
            title
            slug
            position
            ...AllSlugLocales
          }
          model {
            apiKey
          }
        }
      }
    }
  `)

  const publishedPages = menu.allDatoCmsPage.nodes.filter(page => {
    return page.locale === locale
  })

  publishedPages.map(page => {
    page.treeChildren.sort((a, b) => a.position - b.position)
  })

  return publishedPages
}
