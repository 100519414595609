import { useStaticQuery, graphql } from "gatsby"

export const useProduct = () => {
  const menu = useStaticQuery(graphql`
    query UseProductQuery {
      allDatoCmsProductPage(sort: { fields: position }) {
        nodes {
          _allSlugLocales {
            locale
            value
          }
          id
          locale
          title
          slug
        }
      }
    }
  `)

  return menu.allDatoCmsProductPage.nodes
}
