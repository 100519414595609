import { useStaticQuery, graphql } from "gatsby"

export const useCategory = () => {
  const menu = useStaticQuery(graphql`
    query UseCategoryQuery {
      allDatoCmsCategoryPage(sort: { fields: position, order: ASC }) {
        nodes {
          _allSlugLocales {
            locale
            value
          }
          id
          locale
          title
          slug
          application {
            slug
          }
        }
      }
    }
  `)

  return menu.allDatoCmsCategoryPage.nodes
}
