import { useStaticQuery, graphql } from "gatsby"

export const useApplication = () => {
  const menu = useStaticQuery(graphql`
    query UseApplicationQuery {
      allDatoCmsApplicationPage(sort: { fields: position }) {
        nodes {
          id
          locale
          _allSlugLocales {
            locale
            value
          }
          title
          slug
        }
      }
    }
  `)

  return menu.allDatoCmsApplicationPage.nodes
}
