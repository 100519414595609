import React, { useEffect } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { Flex } from "@theme-ui/components"
import DesignTokens from "./designTokens"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faYoutube,
  faLinkedin,
  faWizardsOfTheCoast,
} from "@fortawesome/free-brands-svg-icons"
import { LanguageContext } from "../locales/langContext"
import { Link, OutboundLink } from "./utils/link"
import { ExternalLink } from "react-feather"

const Footer = () => {
  const privacy = {
    it: "https://www.iubenda.com/privacy-policy/32282522",
    en: "https://www.iubenda.com/privacy-policy/61134527",
    de: "https://www.iubenda.com/privacy-policy/29907683",
  }
  const cookie = {
    it: "https://www.iubenda.com/privacy-policy/32282522/cookie-policy",
    en: "https://www.iubenda.com/privacy-policy/61134527/cookie-policy",
    de: "https://www.iubenda.com/privacy-policy/29907683/cookie-policy",
  }

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://cdn.iubenda.com/iubenda.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <LanguageContext.Consumer>
      {t => (
        <Wrapper>
          <Container>
            <Row>
              <Col>
                <Box>
                  <Details>
                    <p>
                      <strong>
                        Gavazzi Tessuti Tecnici S.p.A. Socio Unico
                      </strong>
                    </p>
                    <p>{t.legalAddress}: Via Melzi D’Eril 3 – 20154 MILANO </p>
                    <p>{t.shareCapital}: Euro 12.300.000 i.v.</p>
                    <p>{t.registrationNumber} Registro Imprese di Milano</p>
                    <p>REA MI 1892085</p>
                    <p>{t.vatCode} 02672970130</p>
                  </Details>
                  <Details>
                    <OutboundLink
                      href="tel:+390341641051"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <p>Tel. +39 0341 641.051</p>
                    </OutboundLink>
                    <OutboundLink
                      href="mailto:info@gavazzispa.it"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <p>info@gavazzispa.it</p>
                    </OutboundLink>
                    <OutboundLink
                      href="mailto:admin@gavazzispa.postecert.it"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <p>Pec: admin@gavazzispa.postecert.it</p>
                    </OutboundLink>
                  </Details>
                  <Details>
                    <Social>
                      <OutboundLink
                        href="https://www.youtube.com/channel/UCuPzAo-s1B1-ANWGoH_9zNg/featured"
                        target="blank"
                        rel="nofollow noopener"
                      >
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                      </OutboundLink>
                      <OutboundLink
                        href="https://www.linkedin.com/company/gavazzi-tessuti-tecnici-s.p.a.-in-forma-abbreviata-gavazzi-s.p.a./"
                        target="blank"
                        rel="nofollow noopener"
                      >
                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                      </OutboundLink>
                    </Social>
                  </Details>
                </Box>
              </Col>
            </Row>
            <Row>
              <Col>
                <Box>
                  <Details small>
                    <p>© 2021 Gavazzi S.p.A</p>
                    <Flex sx={{ justifyContent: "space-around" }}>
                      <Link
                        href={privacy[t.locale]}
                        className="iubenda-nostyle no-brand iubenda-embed"
                        title="Privacy Policy"
                        sx={{ color: "light", fontSize: 0, mr: 3 }}
                      >
                        Privacy Policy
                      </Link>
                      <Link
                        href={cookie[t.locale]}
                        className="iubenda-nostyle no-brand iubenda-embed"
                        title="Cookie Policy"
                        sx={{ color: "light", fontSize: 0, mr: 3 }}
                      >
                        Cookie Policy
                      </Link>
                      <Link href="https://gavazzispa-whistleblowing.ethic-channel.com/">
                        Whistleblowing
                      </Link>
                    </Flex>
                  </Details>
                </Box>
              </Col>
            </Row>
          </Container>
        </Wrapper>
      )}
    </LanguageContext.Consumer>
  )
}

const Wrapper = styled.div`
  background: ${DesignTokens.colors.primary[500]};
  color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
`

const Box = styled.div`
  border-top: 1px solid ${DesignTokens.colors.primary[400]};
  padding-top: 3rem;
  padding-bottom: 3rem;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Details = styled.div`
  font-size: ${props => (props.small ? "0.785rem" : "1rem")};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  & > * {
    margin-bottom: 0.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Social = styled.div`
  display: flex;
  flex-direction: row;
  a {
    margin: 0 0.5rem;
  }
`

export default Footer
