import React from "react"

export const languages = {
  it: {
    locale: "it",
    applications: "applicazioni",
    company: "Azienda",
    city: "Località",
    products: "prodotti",
    search: "Cerca",
    results: "risultati",
    noResults: "nessun risultato",
    discoverMore: "Scopri di più",
    legalAddress: "Sede Legale",
    shareCapital: "Capitale sociale",
    registrationNumber: "Numero Iscrizione",
    vatCode: "P. iva e Cod. Fisc.",
    fullName: "Nome e cognome",
    phone: "Telefono",
    message: "Messaggio",
    consent: "Si, acconsento",
    consentPolicy:
      "Dopo aver preso visione della presente informativa sulla privacy ai sensi dell'ART.13 D.Lgs. n.196 del 2003, a) Acconsento al trattamento dei dati personali al fine esclusivo di avere informazioni riguardo i servizi relativi al presente sito web.",
    loading: "Caricamento",
    submit: "Invia",
    submitted: "Richiesta inviata",
    blog: "Blog",
    back: "Indietro",
    noArticle: "Nessun articolo trovato",
    where: "Dove siamo",
    contacts: "Contatti",
    insight: "News",
  },
  en: {
    locale: "en",
    applications: "applications",
    company: "company",
    city: "City",
    products: "products",
    search: "Search",
    results: "results",
    noResults: "no results",
    discoverMore: "Discover more",
    legalAddress: "Legal Head Office",
    shareCapital: "Share capital",
    registrationNumber: "Registration Number",
    vatCode: "VAT Code",
    fullName: "Full name",
    phone: "Phone",
    message: "Message",
    consent: "I agree",
    consentPolicy:
      "Having read the present Privacy Policy pursuant to ART.13 D.Lgs. n.196 of 2003, a) I hereby give my consent to the processing of my personal data for the exclusive purpose of receiving information related to the services provided by this website.",
    loading: "Loading",
    submit: "Submit",
    submitted: "Submitted",
    blog: "Blog",
    back: "Back",
    noArticle: "No articles found",
    where: "Where we are",
    contacts: "Contacts",
    insight: "News",
  },
  de: {
    locale: "de",
    applications: "anwendungen",
    company: "Unternehmen",
    city: "Stadt",
    products: "produkte",
    search: "Suche",
    results: "Ergebnisse",
    noResults: "keine Ergebnisse",
    discoverMore: "Mehr entdecken",
    legalAddress: "Legal Head Office",
    shareCapital: "Share capital",
    registrationNumber: "Registration Number",
    vatCode: "Mehrwertsteuer-Code",
    fullName: "vollständiger Name",
    phone: "Telefon",
    message: "Nachricht",
    consent: "I agree",
    consentPolicy:
      "Having read the present Privacy Policy pursuant to ART.13 D.Lgs. n.196 of 2003, a) I hereby give my consent to the processing of my personal data for the exclusive purpose of receiving information related to the services provided by this website.",
    loading: "Laden",
    submit: "Einreichen",
    submitted: "Eingereicht",
    blog: "Blog",
    back: "Zurück",
    noArticle: "No articles found",
    where: "Wo wir sind",
    contacts: "Kontakte",
    insight: "Nachrichten",
  },
}

export const LanguageContext = React.createContext(languages.it)
